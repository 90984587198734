var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "schema-form" },
    _vm._l(_vm.fields, function (field) {
      return _c("schema-form-field", {
        key: field.id,
        attrs: {
          field: field,
          "contextual-jurisdiction": _vm.contextualJurisdiction,
          disabled: _vm.disabled,
          "ra-address": _vm.raAddress,
          "show-company-mailing-address-option":
            _vm.showCompanyMailingAddressOption,
        },
        on: {
          "suggestion-toggled": function ($event) {
            return _vm.$emit("suggestion-toggled", $event)
          },
          input: _vm.valueUpdated,
          "ra-signup": function ($event) {
            return _vm.$emit("ra-signup")
          },
          "show-contact-modal": _vm.showContactModal,
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }