<template>
  <div
    :class="elementClasses"
    :data-field-path="field.path"
    class="schema-element"
  >
    <div v-if="field.meta.suggestion && !['address','registered_agent'].includes(field.meta.type)">
      <b-row
        v-if="showRaSignup"
        class="mb-0"
      >
        <b-button
          variant="link"
          class="float-left"
          @click="$emit('ra-signup')"
        >
          Sign up to use our address to keep yours private
        </b-button>
      </b-row>

      <b-row>
        <b-form-checkbox
          v-if="showHint"
          v-model="useHint"
          class="float-left ml-3 mb-3"
          :disabled="isDisabled"
          @change="onUseHintToggle"
        >
          {{ field.meta.suggestion.hint }}
        </b-form-checkbox>
      </b-row>
    </div>

    <div v-if="field.type === 'disclaimer'"
         id="duplicate-add-button-breaker-do-not-filter-just-hide-if-needed"
         class="alert alert-info text-center"
         role="alert"
    >
      <b>{{ field.description }} </b>
    </div>

    <div v-if="field.type === 'markup'">
      <div v-html="sanitizedFieldTitle" />
    </div>

    <div v-if="field.type === 'string'">
      <b-form-group :disabled="isUsingHint">
        <schema-form-field-label :label="fieldTitle" :help-text="field.fieldHelpText" />

        <validation-wrapper :field="field" :rule="getRuleForField()">
          <b-form-select
            v-if="field.meta.type === 'select'"
            v-model="value"
            class="text-left"
            :options="field.meta.options"
            @input="valueUpdated"
          />

          <b-form-input
            v-else-if="field.meta.type === 'number'"
            v-model="value"
            type="number"
            :min="field.meta.min_value"
            :max="field.meta.max_value"
            @input="valueUpdated"
          />

          <schema-form-phone-field
            v-else-if="field.meta.type === 'phone'"
            :value="value"
            :field="field"
            @input="valueUpdated"
          />

          <schema-form-date-field
            v-else-if="field.meta.type === 'date'"
            :value="value"
            :field="field"
            @input="valueUpdated"
          />

          <schema-form-ssn-field
            v-else-if="field.meta.type === 'ssn'"
            :value="value"
            :field="field"
            @input="valueUpdated"
          />

          <b-form-input
            v-else
            v-model="value"
            :disabled="disabled || disablePrefilledFields"
            :max-length="field.meta.character_limit"
            @input="valueUpdated"
          />
        </validation-wrapper>
      </b-form-group>
    </div>

    <div v-if="field.type === 'boolean'">
      <validation-wrapper :field="field">
        <b-form-checkbox
          v-model="value"
          :disabled="disabled"
          @input="valueUpdated"
        >
          <schema-form-field-label :label="fieldTitle" :help-text="field.fieldHelpText" />
        </b-form-checkbox>
      </validation-wrapper>
    </div>

    <div v-if="field.type === 'radio'">
      <b-form-group>
        <schema-form-field-label :label="fieldTitle" :help-text="field.fieldHelpText" />

        <validation-wrapper :field="field">
          <b-form-radio-group
            id="field.name"
            v-model="value"
            class="float-left"
            name="value"
            :disabled="disabled"
            :options="field.meta.options"
            @input="valueUpdated"
          />
        </validation-wrapper>
      </b-form-group>
    </div>

    <div v-if="field.type === 'phone'">
      <b-form-group :disabled="isUsingHint">
        <schema-form-field-label :label="fieldTitle" :help-text="field.fieldHelpText" />
        <schema-form-phone-field
          v-model="value"
          :field="field"
          @input="valueUpdated"
        />
      </b-form-group>
    </div>

    <schema-form-person-field
      v-if="field.meta.type === 'person' || field.meta.type === 'registered_agent'"
      v-model="value"
      :field="field"
      :contextual-jurisdiction="contextualJurisdiction"
      :disabled="disabled || disablePrefilledFields"
      :ra-address="raAddress"
      @ra-signup="$emit('ra-signup')"
      @input="valueUpdated"
      @show-contact-modal="showContactModal"
      @suggestion-toggled="suggestionToggled($event)"
    />

    <schema-form-address-field
      v-if="field.meta.type === 'address'"
      v-model="value"
      :field="field"
      :disabled="disabled"
      :contextual-jurisdiction="contextualJurisdiction"
      :initial-use-hint="disablePrefilledFields || addressMatch"
      :show-company-mailing-address-option="showCompanyMailingAddressOption"
      @input="valueUpdated"
      @ra-signup="$emit('ra-signup')"
      @suggestion-toggled="suggestionToggled($event)"
    />

    <div v-if="shouldRenderChildren && field.children.length">
      <b-form-group
        :label="fieldTitle"
        :disabled="isUsingHint"
      >
        <validation-wrapper :field="field">
          <schema-form-field-list
            :fields="field.children"
            :disabled="disabled"
            :contextual-jurisdiction="contextualJurisdiction"
            @input="childValuesUpdated"
          />
        </validation-wrapper>
      </b-form-group>
    </div>

    <b-button v-if="field.canRemove && !disabled" variant="outline-danger" class="mr-1" size="sm" @click="field.remove()">
      - Remove
    </b-button>
    <b-button v-if="field.canHaveMultiple && !disabled" size="sm" variant="outline-primary" @click="field.duplicate()">
      + Add another {{ field.meta ? field.meta.title || field.title : field.title }}
    </b-button>

    <hr v-if="field.type === 'object'">
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import * as DOMPurify from 'dompurify'

export default {
  name: 'SchemaFormField',
  components: {
    SchemaFormFieldList:    () => import('./SchemaFormFieldList'),
    SchemaFormAddressField: () => import('./SchemaFormAddressField'),
    SchemaFormPersonField:  () => import('./SchemaFormPersonField'),
    SchemaFormPhoneField:   () => import('./SchemaFormPhoneField'),
    SchemaFormDateField:    () => import('./SchemaFormDateField'),
    SchemaFormSsnField:     () => import('./SchemaFormSSNField'),
    SchemaFormFieldLabel:   () => import('./SchemaFormFieldLabel'),
    ValidationWrapper:      () => import('../ValidationWrapper'),
  },
  props: {
    field: Object,
    contextualJurisdiction: [Object, String],
    disabled: {
      type: Boolean,
      default: false,
    },
    raAddress: Object,
    showCompanyMailingAddressOption: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      value: null,
      useHint: false,
      fieldMetaType: null,
      nonHintValue: null,
    }
  },
  computed: {
    ...mapGetters('account', [
      'allRequiredInfoPersonByName',
    ]),
    ...mapGetters('companies', [
      'hasProductOfTypeWithCompanyOrInCart',
    ]),
    ...mapGetters('schema', [
      'useWrapperValidations',
      'showAddressPrivacyBorder',
    ]),
    ...mapGetters('checkout', [
      'formationProductInCart',
    ]),
    isUsingHint() {
      return this.useHint || this.disabled
    },
    isDisabled() {
      return this.disableFormCheckbox || this.disabled
    },
    elementClasses() {
      return [
        this.field.type,
        this.field.meta.type,
        this.field.name,
        this.field.isVisible ? 'visible' : 'hidden',
        `level-${this.field.path.match(/\//g).length}`,
        this.field.isValid ? 'valid' : 'invalid',
        this.field.required ? 'required' : null,
      ].filter(i => !!i).map(i => `schema-field__${i}`)
    },

    shouldRenderChildren() {
      return !['address', 'person', 'registered_agent'].includes(this.field.meta.type)
    },

    fieldTitle() {
      return this.field.meta.title || this.field.title
    },
    hasRAProduct() {
      return this.hasProductOfTypeWithCompanyOrInCart('registered-agent', this.contextualJurisdiction)
    },
    skipHint() {
      return this.field?.meta?.suggestion?.context?.skip_hint ||
        this.field?.parent?.meta?.suggestion?.context?.skip_hint
    },
    disablePrefilledFields() {
      return this.field.meta?.suggestion?.context?.pre_fill_and_disable ||
        (this.field.name === 'registered_agent' && this.hasRAProduct) ||
        (this.field.name === 'filer' && this.formationProductInCart) ||
        false
    },
    addressMatch() {
      if (this.field?.useHint != null) return this.field.useHint
      if (this.skipHint) return false

      let raAddress = this.raAddress

      // If RA Address that was passed in is empty check for meta suggestion
      if (!Object.keys(raAddress).length &&
        (this.field.name === 'address' || this.field?.meta?.type === 'address') &&
        this.field?.meta?.suggestion) {
        raAddress = this.field.meta.suggestion
      }

      if (raAddress != null &&
        (!Object.keys(raAddress).length) ||
        this.value == null ||
        typeof(this.value) !== 'object'
      ) {
        return false
      }


      if (raAddress != null && this.value && ('address' in this.value || 'line1' in this.value)) {
        const address = this.value?.address != null ? this.value.address : this.value
        return raAddress.line1 === address.line1 &&
          this.addressLine2Match(raAddress, address) &&
          raAddress.city === address.city &&
          raAddress.state_province_region === address.state_province_region &&
          raAddress.zip_postal_code === address.zip_postal_code
      }
      return false
    },
    disableFormCheckbox() {
      return this.field.meta?.suggestion?.context && !this.hasRAProduct
    },
    showRaSignup() {
      return this.contextualJurisdiction !== undefined &&
        this.field.meta.suggestion.context &&
        this.field.meta.suggestion.context.name === 'registered-agent-service' &&
        !this.field.meta.suggestion.context.skip_hint &&
        !this.hasRAProduct
    },
    showHint() {
      return (this.contextualJurisdiction !== undefined &&
        !this.field.meta.suggestion.context?.skip_hint) &&
        !(this.disablePrefilledFields || this.showAddressPrivacyBorder)
    },
    sanitizedFieldTitle() {
      return DOMPurify.sanitize(this.field.title)
    },
  },
  mounted() {
    this.field.onValueChangeHandler = () => {
      this.value = this.field.value
    }
    this.useHint = this.field.useHint

    this.valueUpdated()
  },
  beforeDestroy() {
    if (this.field) {
      this.field.onValueChangeHandler = null
    }
  },
  methods: {
    addressLine2Match(raAddress, address) {
      return raAddress.line2 == null ?
        this.line2 == null :
        raAddress.line2 === address.line2
    },
    // This catches everything from below when emit('input', object) occurs
    valueUpdated(ev) {
      let val = ev || this.value

      // Merge this value with the parent's field value if it's an object, else overwrite parent's.
      if (this.value && typeof (this.value) === 'object') {
        val = { ...this.field.value, ...this.value }
        if (this.value.suggestionUsed) {
          val.suggestionUsed = true
        } else if (this.value.suggestionUsed === false) {
          val.suggestionUsed = false
        }
      } else {
        this.field.value = val
      }


      // Send the path and the new value to write to it to the parent.
      this.$emit('input', { path: this.field.path, value: val, name: this.field.name })

      if (ev) {
        this.field.validate()
      }
    },

    childValuesUpdated(ev) {
      this.$emit('input', ev)
    },

    onUseHintToggle() {
      if (!this.fieldMetaType) this.fieldMetaType = this.field.meta.type

      this.useHint ? this.usingHint() : this.notUsingHint()

      // this fixes losing the type of the element which happens when toggling checkboxes to text inputs and back
      this.field.meta.type = this.fieldMetaType

      this.valueUpdated()
      this.$emit('suggestion-toggled', { field: this.field, usingSuggestion: this.useHint })
    },

    usingHint() {
      this.nonHintValue = this.value
      this.field.meta.suggestion = this.field.meta.suggestion || {}
      this.value = this.field.type === 'object' ? this.field.meta.suggestion : this.field.meta.suggestion.value || this.field.meta.suggestion
      this.field.meta.suggestion.suggestionUsed = true
      if (typeof this.value === 'object') this.value.suggestionUsed = true
    },

    notUsingHint() {
      if (typeof this.value === 'object') this.value.suggestionUsed = false
      this.field.meta.suggestion = this.field.meta.suggestion || {}
      this.field.meta.suggestion.suggestionUsed = false
      this.value = this.field.meta.type === 'address' ?
        this.setNonHintValue(this.nonHintValue) :
        this.nonHintValue
    },

    setNonHintValue(oldValue) {
      const raAddress = this.field.meta.suggestion.value || this.field.meta.suggestion
      if (raAddress.line1 === oldValue?.line1 || (raAddress && !oldValue)) {
        return {
          'line1': '',
          'line2': '',
          'city': '',
          'state_province_region': '',
          'zip_postal_code': '',
        }
      } else {
        return oldValue
      }
    },

    showContactModal() {
      this.$emit('show-contact-modal')
    },

    suggestionToggled(useHint) {
      this.useHint = useHint
      this.onUseHintToggle()
    },

    // Return the matching rules for ValidationWrapper to use based off a field's type.
    getRuleForField() {
      switch (this.field.meta.type) {
        case 'phone': return 'phone_number'
        case 'ssn':   return 'ssn'
        default:      return ''
      }
    },
  },
}
</script>

<style scoped lang="scss">
  .help-text {
    float: left;
    margin-left: -20px;
  }
  .schema-field__hidden {
    display: none;
  }
</style>
