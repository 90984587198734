<template>
  <div class="schema-form">
    <schema-form-field
      v-for="field in fields"
      :key="field.id"
      :field="field"
      :contextual-jurisdiction="contextualJurisdiction"
      :disabled="disabled"
      :ra-address="raAddress"
      :show-company-mailing-address-option="showCompanyMailingAddressOption"
      @suggestion-toggled="$emit('suggestion-toggled', $event)"
      @input="valueUpdated"
      @ra-signup="$emit('ra-signup')"
      @show-contact-modal="showContactModal"
    />
  </div>
</template>

<script>
  import SchemaFormField from './SchemaFormField'

  export default {
    name: 'SchemaFormFieldList',

    components: {
      SchemaFormField,
    },

    props: {
      fields: Array,
      contextualJurisdiction: [Object, String],
      disabled: {
        type: Boolean,
        default: false,
      },
      registeredAgentField: {
        type: Object,
        default: null,
      },
      showCompanyMailingAddressOption: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        raAddress: {},
      }
    },
    mounted() {
      this.getRaAddress()
    },
    methods: {
      valueUpdated(ev) {
        this.$emit('input', ev)
      },
      showContactModal() {
        this.$emit('show-contact-modal')
      },
      getRaAddress() {
        let raField = this.registeredAgentField || this.fields.find(field => field.name === 'registered_agent')
        let raAddressFields

        if (raField == null) {
          raField = this.fields.find(field => field.name === 'address')
          raAddressFields = raField?._value
        } else {
          raAddressFields = raField?.meta?.suggestion
        }

        if (raAddressFields) {
          const { line1, line2, city, state_province_region, zip_postal_code } = raAddressFields
          this.raAddress = { line1, line2, city, state_province_region, zip_postal_code }
        }
      } ,
    },
  }
</script>
